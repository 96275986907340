<template>
    <div>
        <common-header></common-header>

        <div class="subtop">
            <div class="subtopin">
                <img :src="require('@/assets/img/sub/sub_top_img.png')"/>
                <div class="subtit">ABOUT</div>
            </div>
        </div>

        <div class="sbreadcrumb">
            <div class="bdinner">
                <span><img :src="require('@/assets/img/sub/home_icon.svg')"></span>
                <span><img :src="require('@/assets/img/sub/next_arrow.svg')"></span>
                <span class="locatit">About</span>
            </div>

        </div>

        <div class="scontainer">
            <div class="section">
                <div class="sectionin">
                    <div class="about_logo">
                        <img :src="require('@/assets/img/main/tinteccnc_logo.svg')"/>
                    </div>
                    <p class="stxt text-left">
                        <b>(주) TINTEC C&C</b>는 2019년 9월 프린터 IOT 기술 경험, 수년 간의 모바일 시스템 구축 기술 경험, 프론트 구축 경험, 클라우드 인프라 구성
                        경험을 가진 인재들 중심으로 설립되었습니다.
                        모바일플랫폼 기술력과 시장에서의 경험을 토대로 Solution Development, Consulting, Technical Service 까지의 전과정에서 체계적인
                        서비스를 제공하고 있으며,
                        신기술 트랜드에 기반하여, 플랫폼 개선 및 다양한 모바일 기술의 접목을 통해 보다 차별화 된 Mobile First Service를 제공합니다.
                    </p>
                    <p class="stxt text-left">
                        특히, 프린터 IOT 기술력을 바탕으로 글로벌 프린터 임대 사업 지원 서비스를 직접 운영하면서 IoT Platform Service, Business Platform
                        Service, Messaging Platform Service,
                        Communication Platform Service, Commerce Platform Service, AI Platform Service로 매년 플랫폼 서비스 사업자로
                        성장하겠습니다.
                    </p>
                </div>
            </div>
            <div class="section bggrey">
                <div class="sectionin">
                    <div class="stit">현재 주요 사업 분야</div>
                    <p class="stxt">
                        TINTEC C&C는 비즈니스 및 서비스 프로세스를 간소화 할 수 있는 플랫폼을 제공함으로서
                        고객 비즈니스 효율을 최적화 시키는데 최선을 다하고 있습니다.
                    </p>
                    <div class="row justify-content-center probox">
                        <div class="col-lg-6 text-center">
                            <img :src="require('@/assets/img/sub/about_business_area01.svg')"
                                 data-appear-animation="fadeInLeft"
                                 data-appear-animation-delay="300" data-plugin-options="{'accY': 200}" alt
                                 class="img-fluid"/>
                        </div>
                        <div class="col-md col-lg-6 text-lg-left txtbox">
                            <div class="cttit custom-bar text-color-dark">
                                글로벌 오피스 프린터 임대 사업자를 위한
                                프린터 IOT 제어, 수집 및 사업 지원 클라우드 서비스
                            </div>
                            <p>
                                1. 프린터 IOT 장치를 기반한 프린터 상태 및 페이지 사용량을 모니터링해서 프린터 임대 사업자에게 제공
                            </p>
                            <p>
                                2. 프린터 장애 발생 시 즉시 임대 사업자에게 A/S를 발생시켜 실시간 알림을 제공하고 위치 기반의 지도 맵과 연동해서 직관적인 인터페이스를 제공
                            </p>
                            <p>
                                3. 임대 사업자에게 프린터 일 사용량, 월 사용량, 매출을 연계해서 거래처 자동 결제 처리, 세금 계산서 발행 업무 지원
                            </p>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-md col-lg-6 order-2 order-lg-1 text-lg-left txtbox">
                            <div class="cttit custom-bar _right text-color-dark">SI 비즈니스</div>
                            <p>
                                1. 대기업과 수년간의 Mobile First, Front First SI를 경험이 축적 되고 있으며, 현재까지 지속적으로 SI 구축 후 SM 사업에 참여하고
                                있습니다.
                            </p>
                            <p>
                                2. 자체 운영 서비스는 Kubenetes 클라우드, CI/CD DevOps, Vue Front, Java Spring의 주요 기술로 개발되었습니다.
                            </p>
                            <!--<a href="#downloads" class="btn btn-outline btn-primary text-color-dark custom-btn-style-2 mb-3" data-hash data-hash-offset="62">DOWNLOAD NOW</a>-->
                        </div>
                        <div class="col-lg-6 order-1 order-lg-2 text-center">
                            <img :src="require('@/assets/img/sub/about_img01.png')" class="img-fluid"/>
                        </div>
                    </div>


                </div>
            </div>
            <div class="section">
                <div class="sectionin">
                    <div class="stit">회사 연혁</div>
                    <p class="stxt">
                        (주)TINTEC CNC는 걸어온 길보다 나아가야할 길을 먼저 생각합니다.
                    </p>
                    <div class="aboutimg">
                        <img :src="require('@/assets/img/sub/tinteccnc_history.svg')">
                    </div>
                    <div class="m_history_img">
                        <img :src="require('@/assets/img/sub/tinteccnc_m_history.svg')">
                    </div>
                </div>
            </div>
        </div>
        <common-footer></common-footer>
    </div>


</template>
<script>
    export default{
        metaInfo: {
            title: '틴텍 C&C',
            meta: [
                { charset: 'utf-8'},
                // SEO setting
                { name: 'keywords', content: '틴텍씨앤씨, 틴텍cnc, 틴텍c&c, mps'},
            ]
        }

    }
</script>
<style scoped>


    /*_-------------------------------------------------------------------서브페이지 공통*/

    /*pc header start*/

    #header {
        width: 100%;
        margin: 0 auto;
        height: 100px;

    }

    .m_header {
        display: none;
    }

    .header_container {
        position: absolute;
        height: 100px;
        width: 100%;
        left: 0;
        z-index: 1000;
    }

    .open {
        display: block;
        /*background: #f9f9f9;*/
    }

    /*메뉴 마우스 호버시 나오는 영역입니다._______________________________ start*/
    .header_container {
        position: relative;
        /*background: #f9f9f9;*/
        height: 300px;
    }

    /*메뉴 마우스 호버시 나오는 영역입니다._______________________________ end*/
    .header_container::before {
        content: "";
        position: relative;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100px;
    }

    .header_container.open::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100px;
        background-color: #fff;
    }


    .header_container .inner {
        position: relative;
        width: 100%;
        max-width: 1200px;
        height: 100px;
        margin: 0 auto;
    }


    .header_container .logo {
        position: absolute;
        top: 28px;
        z-index: 111;
    }


    .header_menu_wrap {
        position: absolute;
        height: 100px;
        z-index: 30;
        width: 100%;
        left: 0px;
        top: 37px;
    }

    .header_menu_wrap > ul {
        display: flex;
        justify-content: space-between;
        list-style: none;
        width: 1200px;
        margin: 0 auto;
        position: relative;
        padding-left: 281px;
    }

    .header_menu_wrap > ul > li {
        width: 170px;
        display: inline-block;
        text-align: right;
        position: relative;
    }

    .header_menu_wrap > ul > li > a:hover {
        color: #0f70b7;
    }

    .sub_menu > ul > li:hover {
        text-decoration: underline;
        cursor: pointer;
    }


    .header_menu_wrap > ul > li > a {

        font-size: 1.8rem;
        letter-spacing: -0.54px;
        line-height: 27px;
        font-weight: 700;
        color: #000;
        font-family: 'Noto Sans KR', sans-serif;
    }

    .header_menu_wrap > ul > li > a:hover {
        text-decoration: none;
    }


    .sub_menu {
        /*position: absolute;
        top: 53px;
        z-index: 99;
        padding: 0;
        background-color: red;
        width: 100%;*/
        position: absolute;
        top: 63px;
        left: 50px;
        z-index: 30;
        padding-bottom: 36px;
        display: none;

    }

    .menu01 {
        left: 109px;
    }

    .menu02 {
        left: 71px;
    }

    .menu03 {
        left: 75px;
    }

    .menu04 {
        left: 86px;
    }

    .menu05 {
        left: 68px;
    }


    .sub_menu > ul {
        /* list-style: none;
         padding: 0;
         max-width: 1200px;
         margin: 0 auto;*/
        display: flex;
        flex-direction: column;
        list-style: none;
        padding: 0;


    }

    .sub_menu > ul > li {
        /*width: calc(25% - 36px);*/
        width: 170px;

        padding-top: 26px;
        font-size: 1.4rem;
        line-height: 20px;
        letter-spacing: 0.35px;
        color: #333;
        text-align: left;
    }


    /*pc header end*/


    .subtop {
        width: 100%;
        height: 296px;
        border-top: 1px solid #ddd;
    }

    .subtop .subtopin {
        width: 1200px;
        margin: 0 auto;
    }

    .subtop .subtopin img {
        width: 418px;
        display: inline-block;
        margin-left: 10%;
    }

    .subtop .subtopin .subtit {
        display: inline-block;
        font-size: 4.5rem;
        letter-spacing: -2.25px;
        font-weight: 900;
        color: #000000;
        vertical-align: middle;
    }

    .sbreadcrumb {
        width: 100%;
        background: #0F70B7;
        height: 62px;
    }

    .sbreadcrumb .bdinner {
        width: 1200px;
        margin: 0 auto;
        padding-left: 20px;
    }

    .sbreadcrumb .bdinner span {
        padding-right: 20px;
        vertical-align: bottom;
        display: inline-block;
        padding-top: 19px;
    }

    .sbreadcrumb .bdinner span img {
        vertical-align: sub;
    }

    .sbreadcrumb .bdinner span.locatit {
        font-size: 1.8rem;
        color: #fff;
        font-weight: 900;
        padding-top: 13px;
        display: inline-block;
    }

    .scontainer {
        width: 100%;
    }

    .scontainer .section {
        padding: 100px 20px;
    }

    .scontainer .section .sectionin {
        width: 1200px;
        margin: 0 auto;
        overflow: hidden;
    }

    .scontainer .section .sectionin .stit {
        color: #000;
        font-size: 4.5rem;
        letter-spacing: -1.15px;
        line-height: 70px;
        font-weight: 900;
        /*padding-top: 20px;*/
        text-align: center;
    }

    .scontainer .section .sectionin .stxt {
        font-size: 1.8rem;
        color: #000;
        letter-spacing: -0.9px;
        text-align: center;
        padding-top: 24px;
        line-height: 27px;
    }

    .about_logo {
        width: 242px;
        margin: 0 auto 20px;
    }

    .about_logo img {
        width: 100%;
    }

    /*_-------------------------------------------------------------------서브페이지 공통*/


    .scontainer .section .aboutarea01 {
        padding-top: 50px;
    }

    .scontainer .section .aboutarea01 ul {
        width: 100%;
    }

    .scontainer .section .aboutarea01 ul li {
        display: inline-table;
        width: 25%;
        text-align: center;
    }

    .scontainer .section .aboutarea01 ul li img {
        width: 120px;
        height: 120px;
    }

    .scontainer .section .aboutarea01 ul li h2 {
        font-size: 2rem;
        line-height: 35px;
        color: #000;
        font-weight: 900;
        letter-spacing: -1.12px;
        padding-top: 10px;
        margin-bottom: 0px;
    }

    .scontainer .section .aboutarea01 ul li p {
        font-size: 1.8rem;
        line-height: 24px;
        letter-spacing: -0.54px;
        color: #000;
        padding-top: 10px;
    }

    .scontainer .section.bggrey {
        background-color: #FAFAFA;
    }

    .scontainer .section.bgblue {
        background-color: #F7F9FB;
    }

    .scontainer .section .sectionin .probox {
        margin-bottom: 180px;
        margin-top: 100px;
    }

    .scontainer .section .sectionin .txtbox {
        padding-top: 20px;
    }

    .scontainer .section .sectionin .cttit {
        font-size: 3rem;
        color: #000;
        font-weight: 600;
        line-height: 40px;
        letter-spacing: -1.25px;
        margin-bottom: 20px;
    }

    .txtbox p {
        margin-bottom: 0 !important;
    }

    .scontainer .section .sectionin p {
        font-size: 1.8rem;
        line-height: 32px;
        letter-spacing: -1px;
        color: #000;
    }

    .scontainer .section .sectionin ul {
        /*list-style: none;
        display: flex;
        justify-content: space-between;
        padding-top: 56px;
        padding-left: 0;*/
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        margin-top: 60px;
    }

    .scontainer .section .sectionin ul li {
        /*width:250px;*/
        list-style: none;
        display: flex;
        flex-direction: column;
    }

    .scontainer .section .sectionin ul li img {
        padding-bottom: 27px;
    }

    .scontainer .section .sectionin .aboutimg {
        margin-top: 70px;
        margin-left: 33%;
        width: 580px;
    }

    .scontainer .section .sectionin .aboutimg img {
        width: 100%;
    }

    .detail_view_button {
        display: flex;
        justify-content: center;
    }

    .detail_view_button p,
    .detail_view_button img {
        cursor: pointer;
    }

    .detail_view_button p a {
        color: #333;
    }

    .detail_view_button p:hover {
        text-decoration: underline;
    }

    .detail_view_button img {
        padding-top: 5px;
        width: 7px
    }

    .scontainer .section .sectionin .aboutimg_m {
        display: none;
    }

    .m_history_img {
        display: none;
    }

    .five_content {
        width: 100%;
        height: 202px;
        background-color: #ececec;
        border-bottom: 1px solid #cccccc;
    }

    .five_content > ul {
        padding: 40px 0 0 0;
        font-weight: bold;
        color: #666;
        font-size: 1.4rem;
        width: 1200px;
        margin: 0 auto;
        list-style: none;
        display: flex;
        justify-content: space-between;
    }

    .five_content > ul > li > ul {
        width: 240px;
        list-style: none;
        padding: 16px 0 0 0;
        font-weight: normal;
        color: #9a9a9a;
    }

    .five_content > ul > li > ul > li {
        padding-bottom: 12px;
        cursor: pointer;
    }

    .sub_title{
        font-size: 2rem;
        font-weight: bold;
        color: #333;
        text-align: center;
        padding-bottom: 17px;
    }

    .font_size {
        text-align: center;
        font-size: 1.6rem;
        color: #202020;
        line-height: 20px;
    }

    .footer {
        width: 100%;
        height: 100px;
        background-color: #ececec;
    }

    .footer_wrap {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: start;
        font-size: 1.3rem;
        color: #9a9a9a;
        padding: 23px 20px 0px 0px;
    }

    .footer_wrap > div {
        margin-top: -13px;
    }

    .footer_flex {
        line-height: 18px;
        width: 100%;
        padding-left: 180px;
    }

    .footer_flex span {
        display: block;
        margin-top: 4px;
        font-size: 1.3rem;
        color: #9a9a9a;
    }

    .familyarea {
        margin-left: 72px;
    }

    .familyarea select {
        margin-left: 20px;
    }

    select {
        background: transparent;
        border: 1px solid #cccccc !important;
        width: 170px !important;
        height: 30px;
        color: #9a9a9a;
    }

    /*---------------------------------------------------------------------------------------------------tablet*/
    @media screen and (max-width: 1199px) and (min-width: 750px) {


        .scontainer .section.bggrey {
            background-color: #FAFAFA;
        }

        .scontainer .section .sectionin .probox {
            margin-bottom: 208px;
        }

        .scontainer .section .sectionin .probox img {
            width:100%;
        }

        .scontainer .section .sectionin .txtbox {
            padding-top: 40px;
        }

        .scontainer .section .sectionin .cttit {
            font-size: 4.5rem;
            color: #000;
            font-weight: 900;
            line-height: 62px;
            letter-spacing: -1.25px;
            margin-bottom: 20px;
        }

        .scontainer .section .sectionin p {
            font-size: 1.8rem;
            line-height: 32px;
            letter-spacing: -1px;
            color: #000;
            font-weight: normal;
        }

        .scontainer .section .sectionin .aboutimg {
            margin-top: 70px auto;
            width: 516px;
            margin-left: 33%;
        }

        .scontainer .section .sectionin .aboutimg img {
            width: 100%;
        }


        /*모바일 헤더 메뉴 start */
        #header {
            width: 100%;
            margin: 0 auto;
            height: 50px;
        }

        .inner {
            display: flex;
            justify-content: space-between;
            line-height: 50px;
            padding: 0 16px;
        }

        .logo {
            width: 150px;
            height: 25px;
        }

        .logo > a > img {
            width: 100%;
        }

        .m_header div:nth-child(2) {
            width: 30px;
            height: 30px;
        }

        .m_header div:nth-child(2) img {
            width: 100%;
        }

        .m_header_submenu_wrap {
            position: fixed;
            top: 0;
            overflow-y: auto;
            overflow-x: hidden;
            right: 0;
            z-index: 9999;
            width: 100%;
            height: 100%;
            display: none;
        }

        .side_submenu_li_wrap li {
            list-style: none;
            padding-left: 20px;
            height: 40px;
            line-height: 40px;
            font-size: 1.6rem;
        }

        .side_submenu_li_wrap li a {
            color: #363636;
        }

        .side_submenu ul {
            width: 50%;
            height: 100%;
            background-color: white;
            position: fixed;
            right: 0;
            top: 0;
            padding: 0;
        }

        .sub_menu_background {
            position: fixed;
            width: 100%;
            height: 100%;
            background-color: #000000;
            opacity: 0.6;
        }

        .li_background {
            background-color: #EDF2F6;
        }

        .li_background li {
            padding-bottom: 5px;
        }

        .header_container {
            display: none;
        }

        .m_close_button {
            position: fixed;
            left: 45% !important;
            top: 4px !important;
        }

        .m_close_button > img {
            width: 24px !important;
            height: 24px !important;
        }

        .header_container {
            display: none;
            width: 100%;

        }

        .header_menu_wrap {
            display: none;
        }

        .m_close_button {
            position: fixed;
            left: 340px;
            top: -7px;
        }

        /*모바일 헤더 메뉴 end */
        .subtop .subtopin {
            width: 1200px;
            margin: 0 auto%;
        }


        .scontainer .section .sectionin {
            width: 100%;
            padding: 0 20px;
        }

        .scontainer .section .sectionin .proimg {
            width: 100%;
        }

        .scontainer .section .aboutarea01 {
            padding-top: 50px;
        }

        .scontainer .section .aboutarea01 ul {
            width: 100%;
        }

        .scontainer .section .aboutarea01 ul li {
            display: inline-table;
            width: 50%;
            text-align: center;
            margin-top: 40px;
        }

        .scontainer .section .sectionin .probox {
            margin-bottom: 20px;
        }

        .scontainer .section .sectionin .txtbox {
            padding-top: 40px;
        }

        .scontainer .section .sectionin .cttit {
            font-size: 2rem;
            color: #000;
            font-weight: 900;
            line-height: 32px;
            letter-spacing: -1.25px;
            margin-bottom: 10px;
        }

        .scontainer .section .sectionin p {
            font-size: 1.6rem;
            line-height: 24px;
            letter-spacing: -1px;
            color: #000;
            font-weight: normal;
        }

        .scontainer .section .aboutarea01 ul li p {
            font-size: 1.6rem;
            line-height: 20px;
            letter-spacing: -0.42px;
            color: #000;
            padding-top: 8px;
        }

        .m_history_img {
            display: none;
        }

        .five_content > ul {
            width: 100%;
            margin: 0 auto;
            font-size: 1.3rem;
            letter-spacing: -0.5px;
        }

        .five_content > ul > li > ul {
            width: 100%;
        }

        .footer .footer_wrap {
            width: 100%;
            padding: 0 40px;
        }

        .footer_wrap > div {
            margin-top: 14px;
        }

        .footer_flex span {
            display: block;
            margin-top: 4px;
            font-size: 1.3rem;
            color: #9a9a9a;
        }

        .familyarea select {
            margin-left: 0px;
        }


    }


    /*---------------------------------------------------------------------------------------------------mobile*/
    @media (max-width: 749px) {
        /*grid*/
        /*모바일 헤더 메뉴 start */
        #header {
            width: 100%;
            margin: 0 auto;
            height: 50px;
        }

        .inner {
            display: flex;
            justify-content: space-between;
            line-height: 50px;
            padding: 0 16px;
        }

        .logo {
            width: 105px;
            height: 25px;
        }

        .logo > a > img {
            width: 100%;
        }

        /*모바일 헤더 메뉴 start */
        .m_header_submenu_wrap {
            position: fixed;
            top: 0;
            overflow-y: auto;
            overflow-x: hidden;
            right: 0;
            z-index: 9999;
            width: 100%;
            height: 100%;
            display: none;
        }

        .side_submenu_li_wrap li {
            list-style: none;
            padding-left: 20px;
            height: 40px;
            line-height: 40px;
        }

        .side_submenu_li_wrap li a {
            color: #363636;
        }

        .side_submenu ul {
            width: 70%;
            height: 100%;
            background-color: white;
            position: fixed;
            right: 0;
            top: 0;
            padding: 0;
            overflow-y: auto;
        }

        .sub_menu_background {
            position: fixed;
            width: 100%;
            height: 100%;
            background-color: #000000;
            opacity: 0.6;
        }

        .li_background {
            background-color: #EDF2F6;
        }

        .li_background li {
            padding-bottom: 5px;
        }

        .header_container {
            display: none;
        }

        .m_close_button {
            position: fixed;
            left: 24%;
            top: -7px;
        }

        /*모바일 헤더 메뉴 end */
        .row {
            margin-left: 0px;
            margin-right: 0px;
        }

        .col-lg-6 {
            padding-right: 0px;
            padding-left: 0px;
        }

        .about_logo {
            width: 140px;
            margin: 0 auto;
        }

        .about_logo img {
            width: 100%;
            margin-bottom: 30px;
        }

        .scontainer .section {
            padding: 32px 0;
        }

        .subtop {
            width: 100%;
            height: 97px;
            border-bottom: 1px solid #0F70B7;
            border-top: block;
        }

        .subtop .subtopin {
            width: 100%;
            margin: 0 auto;
        }

        .subtop .subtopin img {
            width: 135px;
            display: inline-block;
            margin-left: 10%;
        }

        .subtop .subtopin .subtit {
            display: inline-block;
            font-size: 2rem;
            letter-spacing: -1px;
            font-weight: 900;
            color: #000000;
            vertical-align: middle;
        }

        .sbreadcrumb {
            display: none;
        }

        .scontainer .section.bggrey {
            background-color: #FAFAFA;
            padding-top: 60px;
        }

        .scontainer .section .sectionin {
            width: 100%;
            overflow: hidden;
            padding: 0 20px;
        }

        .scontainer .section .sectionin .stit {
            color: #000;
            font-size: 2rem;
            letter-spacing: -1px;
            line-height: 30px;
            font-weight: 900;
            padding: 20px 0 30px;
            text-align: center;
        }

        .scontainer .section .sectionin .stxt {
            font-size: 1.4rem;
            color: #000;
            line-height: 24px;
            letter-spacing: -0.7px;
            text-align: left;
            padding-top: 24px;
            line-height: 24px;
        }


        .scontainer .section .aboutarea01 {
            padding-top: 0px;
        }

        .scontainer .section .aboutarea01 ul {
            width: 100%;
            padding-left: 0px;
        }

        .scontainer .section .aboutarea01 ul li {
            display: inline-table;
            width: 100%;
            text-align: center;
            margin-top: 40px;
        }

        .scontainer .section .aboutarea01 ul li h2 {
            font-size: 1.6rem;
            line-height: 35px;
            color: #000;
            font-weight: 900;
            letter-spacing: -0.9px;
            padding-top: 8px;
            margin-bottom: 0px;
        }

        .scontainer .section .aboutarea01 ul li p {
            font-size: 1.4rem;
            line-height: 20px;
            letter-spacing: -0.42px;
            color: #000;
            padding-top: 8px;
        }


        .scontainer .section .sectionin .probox {
            margin-bottom: 42px;
            margin-top: 34px;
        }

        .scontainer .section .sectionin .txtbox {
            padding-top: 22px;
        }

        .scontainer .section .sectionin h2 {
            font-size: 2rem;
            color: #000;
            font-weight: 900;
            line-height: 30px;
            letter-spacing: -1px;
            margin-bottom: 6px;
        }

        .scontainer .section .sectionin p {
            font-size: 1.6rem;
            line-height: 20px;
            letter-spacing: -0.35px;
            color: #000;
            /*font-weight: 600;*/
        }

        .m_history_img {
            display: block;
            width: 278px;
        }

        .m_history_img > img {
            width: 100%;
        }

        .scontainer .section .sectionin .aboutimg {
            display: none;
        }

        .scontainer .section.bggrey {
            background-color: #FAFAFA;
            padding-top: 32px;
        }

        .scontainer .section .sectionin .cttit {
            font-size: 2rem;
            color: #000;
            font-weight: 900;
            line-height: 32px;
            letter-spacing: -1.25px;
            margin-bottom: 10px;
        }

        .detail_view_button img {
           /* display: none;*/
            width: 7px;
            margin-bottom: 18px;
            padding: 0;
        }

        .detail_view_button > p{
            margin: 5px 10px 26px 0 !important;
        }

        .scontainer .section .sectionin .stxt {
            padding: 0;
            text-align: center;
        }

        .scontainer .section .sectionin p {
            font-size: 1.4rem;
            line-height: 24px;
            letter-spacing: -1px;
            color: #000;
            margin-bottom: 40px;
            /*font-weight: 600;*/
        }

        .scontainer .section .sectionin ul {
            display: flex;
            flex-direction: column;
            width: 70%;
            margin: 0 auto;
            padding-left: 0;
        }

        .scontainer .section .sectionin ul li {
            margin-bottom: 30px;
        }

        .scontainer .section .sectionin ul li img{
            width:100% !important;
        }


        .footer_wrap > div {
            text-align: center;
            margin: 0 auto;
        }

        .footer_wrap > div > img {
            width: 140px;
            height: 55px;
        }



    }


</style>


<script>
    import CommonHeader from "@/components/CommonHeader";
    import CommonFooter from "@/components/CommonFooter";

    export default {
        name: "About",
        components: {
            CommonFooter,
            CommonHeader
        },
        data() {
            return {
                imgUrl: require('@/assets/img/landing/tb_logo2.png'),
                loading: true
            }
        },
        created() {

        },
        mounted() {
            setTimeout(() => {
                this.loading = false;
            }, 2000)
        }
    }
</script>

